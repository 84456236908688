
import { Component, ComponentType, JSX, lazy } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { Helmet } from "react-helmet";

import { contains, setTitle } from "./utils/functions";

const HomePageInitial: ComponentType<any> = lazy(() => import("./home_page/home_page.initial")),
	AboutUsPage: ComponentType<any> = lazy(() => import("./about_us_page/about_us_page")),
	ContactUsPage: ComponentType<any> = lazy(() => import("./contact_us_page/contact_us_page")),
	ContentPageInitial: ComponentType<any> = lazy(() => import("./content_page/content_page.initial")),
	ExplorePageInitial: ComponentType<any> = lazy(() => import("./explore_page/explore_page.initial")),
	LoginPageInitial: ComponentType<any> = lazy(() => import("./login_page/login_page.initial")),
	LogoutPage: ComponentType<any> = lazy(() => import("./logout_page/logout_page")),
	PRContentPageInitial: ComponentType<any> = lazy(
		() => import("./prcontent_page/prcontent_page.initial")
	),
	PrivacyPolicyPage: ComponentType<any> = lazy(
		() => import("./privacy_policy_page/privacy_policy_page")
	),
	ProductionPageInitial: ComponentType<any> = lazy(
		() => import("./production_page/production_page.initial")
	),
	SpotlightPageInitial: ComponentType<any> = lazy(
		() => import("./spotlight_page/spotlight_page.initial")
	),
	UserPageIntial: ComponentType<any> = lazy(() => import("./user_page/user_page.initial")),
	UserSettingPageInitial: ComponentType<any> = lazy(
		() => import("./user_setting_page/user_setting_page.initial")
	),
	Page404: ComponentType<any> = lazy(() => import("./page_404/page_404"));





interface Sites {
	[k: string]: {
		readonly tag: string;
		readonly paths: string[][];
	};
};

export const sites: Sites = {

	"home_page": { tag: "home_page", paths: [ [ "index.html" ] ] },

	// About Us Page
	"about_us_page": { tag: "about_us_page", paths: [ [ "about-us" ] ] },

	// Contact Us Page
	"contact_us_page": { tag: "contact_us_page", paths: [ [ "contact-us" ] ] },

	// Content Page
	"content_page": { tag: "content_page", paths: [ [ "content", ":type", ":id" ] ] },

	// PRContent Page
	"prcontent_page": { tag: "prcontent_page", paths: [ [ "media", ":type", ":id" ] ] },

	// Privacy Policy Page
	"privacy_policy_page": { tag: "privacy_policy_page", paths: [ [ "privacy-policy" ] ] },

	// Production Page
	"production_page": { tag: "production_page", paths: [ [ "production", ":id" ] ] },

	// Explore Page
	"explore_page": { tag: "explore_page", paths: [ [ "explore" ] ] },

	// Spotlight Page
	"spotlight_page": { tag: "spotlight_page", paths: [ [ "spotlight" ] ] },

	// Login Page
	"login": { tag: "login_page", paths: [ [ "login" ] ] },
	"signup": { tag: "login_page", paths: [ [ "signup" ] ] },
	"forgot_your_password": { tag: "login_page", paths: [ [ "forgot-your-password" ] ] },
	"password_update": { tag: "login_page", paths: [ [ "password-update", ":token" ] ] },

	// Logout Page
	"logout": { tag: "logout_page", paths: [ [ "logout" ] ] },

	// User Page
	"user_page": { tag: "user_page", paths: [ [ "user" ] ] },

	// User Setting Page
	"user_setting_page": { tag: "user_setting_page", paths: [ [ "user", "setting" ] ] },

	// 404
	"404": { tag: "404_page", paths: [] }
};





export default class App extends Component<any, any> {

	private pathnames: string[] = [];



	/*
		-----------
		Elements
		-----------
	*/

	public render(): JSX.Element {
		this.pathnames = window.location.pathname.split('/').filter(
			(i: string): boolean => contains(i)
		);



		// Get_Notification_Registration_ID()
		// .then((id: string): void => {

		// }).catch((error: Error): void => {
		// 	console.log(error);
		// });



		// console.log(this.pathnames);

		return (
			<BrowserRouter>
				<Routes>

					{/*
						----- Home Page -----
					*/}

					{(!contains(this.pathnames) || (sites["home_page"].paths[0][0] === this.pathnames[0])) && (
						<Route
							caseSensitive={true}
							path={window.location.pathname}
							element={<>
								<Helmet
									async={true} title={setTitle("Home")}
									htmlAttributes={{ "page": sites["home_page"].tag }}
								>
									<title>{setTitle("Home")}</title>
									<meta
										name="description"
										content="Follow Movies, TV Shows and Channels to view their latest posts."
									/>
									{/* <meta name="robots" content="noindex" /> */}
								</Helmet>

								<HomePageInitial />
							</>}
						/>
					)}



					{/*
						----- About Us Page -----
					*/}

					{(sites["about_us_page"].paths[0][0] === this.pathnames[0]) && (
						<Route
							caseSensitive={true}
							path={window.location.pathname}
							element={<>
								<Helmet
									async={true} title={setTitle("About Us")}
									htmlAttributes={{ "page": sites["about_us_page"].tag }}
								>
									<title>{setTitle("About Us")}</title>
									<meta name="description" content="About Us Page" />
									{/* <meta name="robots" content="noindex" /> */}
								</Helmet>

								<AboutUsPage />
							</>}
						/>
					)}



					{/*
						----- Contact Us Page -----
					*/}

					{(sites["contact_us_page"].paths[0][0] === this.pathnames[0]) && (
						<Route
							caseSensitive={true}
							path={window.location.pathname}
							element={<>
								<Helmet
									async={true} title={setTitle("Contact Us")}
									htmlAttributes={{ "page": sites["contact_us_page"].tag }}
								>
									<title>{setTitle("Contact Us")}</title>
									<meta name="description" content="Contact Us Page" />
									{/* <meta name="robots" content="noindex" /> */}
								</Helmet>

								<ContactUsPage />
							</>}
						/>
					)}



					{/*
						----- Content Page -----
					*/}

					{(
						(sites["content_page"].paths[0][0] === this.pathnames[0])
					) && (
						<Route
							caseSensitive={true}
							path={`/${sites["content_page"].paths[0].join('/')}`}
							element={<>
								<Helmet async={true} htmlAttributes={{ "page": sites["content_page"].tag }} />
								<ContentPageInitial />
							</>}
						/>
					)}



					{/*
						----- PRContent Page -----
					*/}

					{(
						(sites["prcontent_page"].paths[0][0] === this.pathnames[0])
					) && (
						<Route
							caseSensitive={true}
							path={`/${sites["prcontent_page"].paths[0].join('/')}`}
							element={<>
								<Helmet async={true} htmlAttributes={{ "page": sites["prcontent_page"].tag }} />
								<PRContentPageInitial />
							</>}
						/>
					)}



					{/*
						----- Privacy Policy Page -----
					*/}

					{(sites["privacy_policy_page"].paths[0][0] === this.pathnames[0]) && (
						<Route
							caseSensitive={true}
							path={window.location.pathname}
							element={<>
								<Helmet
									async={true} title={setTitle("Privacy Policy")}
									htmlAttributes={{ "page": sites["privacy_policy_page"].tag }}
								>
									<title>{setTitle("Privacy Policy")}</title>
									<meta name="description" content="Privacy Policy Page" />
								</Helmet>

								<PrivacyPolicyPage />
							</>}
						/>
					)}



					{/*
						----- Production Page -----
					*/}

					{(
						(sites["production_page"].paths[0][0] === this.pathnames[0])
					) && (
						<Route
							caseSensitive={true}
							path={`/${sites["production_page"].paths[0].join('/')}`}
							element={<>
								<Helmet async={true} htmlAttributes={{ "page": sites["production_page"].tag }} />
								<ProductionPageInitial />
							</>}
						/>
					)}



					{/*
						----- Explore Page -----
					*/}

					{(sites["explore_page"].paths[0][0] === this.pathnames[0]) && (
						<Route
							caseSensitive={true}
							path={window.location.pathname}
							element={<>
								<Helmet
									async={true} title={setTitle("Explore")}
									htmlAttributes={{ "page": sites["explore_page"].tag }}
								>
									<title>{setTitle("Explore")}</title>
									<meta
										name="description"
										content="Explore and discover content to watch on various streaming service platforms."
									/>
								</Helmet>

								<ExplorePageInitial />
							</>}
						/>
					)}



					{/*
						----- Spotlight Page -----
					*/}

					{(sites["spotlight_page"].paths[0][0] === this.pathnames[0]) && (
						<Route
							caseSensitive={true}
							path={window.location.pathname}
							element={<>
								<Helmet
									async={true} title={setTitle("Spotlight")}
									htmlAttributes={{ "page": sites["spotlight_page"].tag }}
								>
									<title>{setTitle("Spotlight")}</title>
									<meta
										name="description"
										content="Explore and discover content that have been highlighted with spotlights."
									/>
								</Helmet>

								<SpotlightPageInitial />
							</>}
						/>
					)}



					{/*
						----- Login Page -----
					*/}

					{(sites["login"].paths[0][0] === this.pathnames[0]) && (
						<Route
							caseSensitive={true}
							path={window.location.pathname}
							element={<>
								<Helmet async={true} htmlAttributes={{ "page": sites["login"].tag }} />

								<LoginPageInitial />
							</>}
						/>
					)}

					{/* Signup */}

					{(sites["signup"].paths[0][0] === this.pathnames[0]) && (
						<Route
							caseSensitive={true}
							path={window.location.pathname}
							element={<>
								<Helmet async={true} htmlAttributes={{ "page": sites["signup"].tag }} />

								<LoginPageInitial />
							</>}
						/>
					)}

					{/* Forgot Your Password */}

					{(sites["forgot_your_password"].paths[0][0] === this.pathnames[0]) && (
						<Route
							caseSensitive={true}
							path={window.location.pathname}
							element={<>
								<Helmet async={true} htmlAttributes={{ "page": sites["forgot_your_password"].tag }} />

								<LoginPageInitial />
							</>}
						/>
					)}

					{/* Password Update */}

					{(sites["password_update"].paths[0][0] === this.pathnames[0]) && (
						<Route
							caseSensitive={true}
							path={window.location.pathname}
							element={<>
								<Helmet async={true} htmlAttributes={{ "page": sites["password_update"].tag }} />

								<LoginPageInitial />
							</>}
						/>
					)}



					{/*
						----- Logout Page -----
					*/}

					{(sites["logout"].paths[0][0] === this.pathnames[0]) && (
						<Route
							caseSensitive={true}
							path={window.location.pathname}
							element={<>
								<Helmet
									async={true} title={setTitle("Logout")}
									htmlAttributes={{ "page": sites["logout"].tag }}
								>
									<title>{setTitle("Logout")}</title>
									<meta name="description" content="Logout Page" />
								</Helmet>

								<LogoutPage />
							</>}
						/>
					)}



					{/*
						----- User Page -----
					*/}

					{(this.pathnames[0] === "user") && <>
						{(sites["user_setting_page"].paths[0][1] === this.pathnames[1]) ? (
							<Route
								caseSensitive={true}
								path={window.location.pathname}
								element={<>
									<Helmet async={true} htmlAttributes={{ "page": sites["user_setting_page"].tag }} />

									<UserSettingPageInitial />
								</>}
							/>
						) : (
							<Route
								caseSensitive={true}
								path={window.location.pathname}
								element={<>
									<Helmet async={true} htmlAttributes={{ "page": sites["user_page"].tag }} />

									<UserPageIntial />
								</>}
							/>
						)}
					</>}



					<Route
						path='*'
						element={<>
							<Helmet
								async={true} title={setTitle("404")}
								htmlAttributes={{ "page": sites["404"].tag }}
							>
								<title>{setTitle("404")}</title>
								<meta name="description" content="Page/Content not found" />
							</Helmet>

							<Page404 />
						</>}
					/>

				</Routes>
			</BrowserRouter>
		);
	}
}
